export const expatHealthPolicyCancelReasons = [
  'FOUND_A_JOB',
  'LEFT_GERMANY',
  'FOUND_ANOTHER_INSURANCE',
  'ADJUST_POLICY',
  'RIGHT_OF_WITHDRAWAL',
  'DELINQUENCY',
  'OTHER',
] as const;

export type ExpatHealthPolicyCancelReason =
  typeof expatHealthPolicyCancelReasons[number];
