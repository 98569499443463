import { ExpatHealthPolicyCancelReason } from '../models';

export const expatHealthCancelReasonMapper: Record<
  ExpatHealthPolicyCancelReason,
  string
> = {
  FOUND_A_JOB: 'Found a job',
  LEFT_GERMANY: 'Left Germany',
  FOUND_ANOTHER_INSURANCE: 'Found another insurance',
  ADJUST_POLICY: 'Adjust policy',
  RIGHT_OF_WITHDRAWAL: 'Right of withdrawal',
  DELINQUENCY: 'Deliquency',
  OTHER: 'Other',
};
