import {
  DocumentNode,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import Button from 'components/Button';
import { ModalForm } from 'components/ModalForm';
import { ModalFormData } from 'components/ModalForm/models';
import { useDebounce } from 'hooks/useDebounce';
import { GET_CUSTOMERS } from 'pages/customers/graphql/queries';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Country } from 'shared/countries/models';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';
import { ZodObject, ZodRawShape, ZodUnion } from 'zod';

import {
  HRTemplateInsuranceType,
  TemplateInsuranceType,
} from '../models/InsuranceTypes';

export interface CreatePolicySchema {
  newCustomer: boolean;
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  provider: string;
  status: string;
  startDate: string;
  policyNumber: string;
  price: number;
  customerSalary: number;
  file: File;
  fileName: string;
}

interface Props {
  insuranceType: TemplateInsuranceType | HRTemplateInsuranceType;
  createPolicyMutation: DocumentNode;
  createPolicySubmissionSchema:
    | ZodObject<ZodRawShape>
    | ZodUnion<[ZodObject<ZodRawShape>, ZodObject<ZodRawShape>]>;
  refetchQueries: DocumentNode[];
  getFormData: (
    customers: any[],
    setCustomersSearchQuery: (query: string) => void,
    customersLoading: boolean,
    insuranceType?: TemplateInsuranceType | HRTemplateInsuranceType
  ) => ModalFormData<CreatePolicySchema, string>[];
}

export const CreatePolicy = ({
  insuranceType,
  createPolicyMutation,
  refetchQueries,
  getFormData,
  createPolicySubmissionSchema,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const debouncedValue = useDebounce(customerSearchQuery, 1_000);
  const { country: region } = useParams<{
    country: Country;
  }>();

  const { loading: customersLoading, data: customersData } = useQuery(
    GET_CUSTOMERS,
    {
      variables: {
        searchString: debouncedValue,
        offset: 0,
        limit: 20,
        sortColumn: '',
        sortOrder: '',
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const alertBannersState = useReactiveVar(alertBanners);

  const onSubmitSuccess = () => {
    setIsOpen(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'New policy was successfully created.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onSubmitError = () => {
    setIsOpen(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [createClaim, { loading: createClaimLoading }] = useMutation(
    createPolicyMutation,
    {
      refetchQueries,
      notifyOnNetworkStatusChange: true,
      onCompleted: onSubmitSuccess,
      onError: onSubmitError,
      errorPolicy: 'none',
    }
  );

  const onSubmit = async (answers: any) => {
    createClaim({
      variables: {
        ...answers,
        insuranceType,
        regionOfPurchase: region,
        ...(answers.price ? { price: Number(answers.price) } : {}),
        ...(answers.customerSalary
          ? { customerSalary: Number(answers.customerSalary) }
          : {}),
      },
    });
  };

  const loading = customersLoading || createClaimLoading;

  const formData = getFormData(
    customersData?.customers ?? [],
    setCustomerSearchQuery,
    customersLoading,
    insuranceType
  );
  return (
    <>
      <Button buttonType="primary" onClick={() => setIsOpen(true)}>
        New policy
      </Button>
      <ModalForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={loading}
        submitButtonLabel="Create"
        title="New policy"
        formData={formData}
        onSubmit={onSubmit}
        submitValidationSchema={createPolicySubmissionSchema}
        bottomElement={
          <div className="mt-6 text-sm text-gray-600">
            Once confirmed, a new policy will be created, and the customer will
            be able to view it on their policy dashboard.
          </div>
        }
      />
    </>
  );
};
