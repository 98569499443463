/**
 * NOTE: This type is being shared with BE.
 * When updating, also update BE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const dogLiabilityClaimStatuses = [
  'RECEIVED',
  'SENT',
  'INCOMPLETE',
  'MISSING_INFO_RECEIVED',
  'OTHER_PAID_OUT',
  'CUSTOMER_PAID_OUT',
  'DENIED',
  'DUPLICATE',
  'IN_REVIEW',
  'DROPPED_OUT',
  'ON_HOLD',
  'CLOSED',
] as const;
export type DogLiabilityClaimStatus = typeof dogLiabilityClaimStatuses[number];

export const dogLiabilityClaimTypes = [
  'PROPERTY_DAMAGE',
  'INJURY_TO_OTHERS',
  'OTHER',
] as const;
export type DogLiabilityClaimType = typeof dogLiabilityClaimTypes[number];
