export const expatSpainPolicyCancelReasons = [
  'FOUND_A_JOB',
  'LEFT_SPAIN',
  'FOUND_ANOTHER_INSURANCE',
  'RIGHT_OF_WITHDRAWAL',
  'DELINQUENCY',
] as const;

export type ExpatSpainPolicyCancelReason =
  typeof expatSpainPolicyCancelReasons[number];

export const expatSpainCancelReasonMapper: Record<
  ExpatSpainPolicyCancelReason,
  string
> = {
  FOUND_A_JOB: 'Found a job',
  LEFT_SPAIN: 'Left Spain',
  FOUND_ANOTHER_INSURANCE: 'Found another insurance',
  RIGHT_OF_WITHDRAWAL: 'Right of withdrawal',
  DELINQUENCY: 'Delinquency',
};
