import { DogLiabilityClaimStatus } from './models';

export const statusNameMapper: Record<DogLiabilityClaimStatus, string> = {
  RECEIVED: 'Received',
  INCOMPLETE: 'Missing info',
  MISSING_INFO_RECEIVED: 'Info received',
  OTHER_PAID_OUT: 'Paid to 3rd party',
  CUSTOMER_PAID_OUT: 'Customer paid out',
  DENIED: 'Denied',
  DUPLICATE: 'Duplicate',
  IN_REVIEW: 'In review',
  DROPPED_OUT: 'Dropped out',
  ON_HOLD: 'On hold',
  CLOSED: 'Closed',
  SENT: 'Sent',
};
