import { BadgeColor } from 'components/Badge/Badge';

import { DogLiabilityClaimStatus } from './models';

export const badgeColorMapper: Record<DogLiabilityClaimStatus, BadgeColor> = {
  RECEIVED: 'purple',
  INCOMPLETE: 'red',
  MISSING_INFO_RECEIVED: 'purple',
  OTHER_PAID_OUT: 'green',
  CUSTOMER_PAID_OUT: 'green',
  DENIED: 'yellow',
  DUPLICATE: 'gray',
  IN_REVIEW: 'blue',
  DROPPED_OUT: 'gray',
  ON_HOLD: 'gray',
  CLOSED: 'gray',
  SENT: 'blue',
};
