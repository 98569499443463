import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { StatusData } from 'pages/claims/template/models';
import { GenericClaimStatus } from 'shared/claims/generic/models';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

import { getGenericClaimStatuses } from '../../utils';

export const genericClaimStatusOptions = (
  insuranceType: InsuranceType
): SelectButtonOption<GenericClaimStatus>[] => {
  const claimStatuses: GenericClaimStatus[] = getGenericClaimStatuses();

  return claimStatuses.map((statusId) => ({
    id: statusId,
    label: policyMappers.GENERIC?.claim?.claimStatusMapper[statusId] ?? '',
    title: policyMappers.GENERIC?.claim?.claimStatusMapper[statusId] ?? '',
    color: policyMappers.GENERIC?.claim?.badgeColorMapper[statusId],
  }));
};

export const retrieveFormAndFooterTextByStatus = (
  status: GenericClaimStatus
): StatusData => ({
  form: formMapper[status],
  footerText: footerTextMapper(status),
});

const uploadForm: StatusUpdateFormQuestion = {
  id: 'uploadDocument',
  label: 'Upload document',
  data: {
    type: 'UPLOAD',
    fileName: {
      placeholder: 'Enter document friendly name',
      id: 'fileName',
    },
  },
};

const payoutAmountForm: StatusUpdateFormQuestion = {
  id: 'payoutAmount',
  label: 'Payout amount',
  data: { type: 'CURRENCY', placeholder: '0.00' },
  required: true,
};

const featherPaidOnForm: StatusUpdateFormQuestion = {
  id: 'featherPaidOn',
  label: 'Feather paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const customerPaidOnForm: StatusUpdateFormQuestion = {
  id: 'customerPaidOn',
  label: 'Customer paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const thirdPartyPaidOnForm: StatusUpdateFormQuestion = {
  id: 'thirdPartyPaidOn',
  label: '3rd party paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const formMapper: Record<GenericClaimStatus, StatusUpdateFormQuestion[]> = {
  DENIED: [uploadForm],
  INCOMPLETE: [uploadForm],
  MISSING_INFO_RECEIVED: [uploadForm],
  SUBMITTED_TO_PROVIDER: [],
  FEATHER_PAID_OUT: [payoutAmountForm, featherPaidOnForm, uploadForm],
  CUSTOMER_PAID_OUT: [payoutAmountForm, customerPaidOnForm, uploadForm],
  OTHER_PAID_OUT: [payoutAmountForm, thirdPartyPaidOnForm, uploadForm],
  DUPLICATE: [uploadForm],
  DROPPED_OUT: [uploadForm],
  RECEIVED: [uploadForm],
  CLOSED: [uploadForm],
  APPROVED_WITH_OTHER_CLAIMS: [uploadForm],
};

const footerTextMapper = (status: GenericClaimStatus): string | undefined => {
  if (status !== 'SUBMITTED_TO_PROVIDER') {
    return 'Once confirmed, claim status will be updated and documents will be added to the claim.';
  }
};
